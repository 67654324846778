<template>
  <gc-dialog
    :show.sync="visible"
    :title="title"
    @ok="handleOk"
    @cancel="visible = false"
    @close="visible = false"
  >
    <el-form ref="form" :model="form" :rules="formRules" label-position="top">
      <el-form-item label="设备编号" prop="deviceNo">
        <el-input v-model="form.deviceNo" placeholder="请填写" />
      </el-form-item>
      <el-form-item label="过期时间" prop="expirationTime">
        <el-date-picker
          v-model="form.expirationTime"
          type="datetime"
          placeholder="请选择过期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
  </gc-dialog>
</template>

<script>
import { ruleRequired, ruleMaxLength } from "@/utils/rules";
import { apiAddMember, apiUpdateMember } from "@/api/member";

export default {
  name: "EditDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        deviceNo: "",
        expirationTime: "",
      },
      formRules: {
        deviceNo: [ruleRequired("请填写设备编号"), ruleMaxLength(64)],
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
    title() {
      return this.detail ? "修改会员" : "新增会员";
    },
  },
  watch: {
    show(val) {
      if (val && this.detail) {
        this.form = {
          ...this.form,
          ...this.detail,
        };
      } else {
        this.form = this.$options.data().form;
        this.$refs.form?.resetFields();
      }
    },
  },
  methods: {
    handleOk() {
      let flag = false;
      this.$refs.form.validate((valid) => {
        flag = valid;
      });
      if (!flag) return;
      const api = this.detail ? apiUpdateMember : apiAddMember;
      const { deviceNo, expirationTime } = this.form;
      const params = { deviceNo, expirationTime };
      api(params)
        .then((res) => {
          console.log(res);
          this.$message.success(
            this.detail ? "修改会员成功！" : "新增会员成功！"
          );
          this.$emit("update", this.detail);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-textarea__inner {
  height: 64px;
}
</style>
