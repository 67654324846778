import request from "./request";

// 批量新增
export const apiBatchAddMember = (data) => {
  return request({
    url: "/monitor/device/member/batch",
    method: "POST",
    data,
  });
};
// 批量刪除
export const apiBatchDelMember = (data) => {
  return request({
    url: "/monitor/device/member/batch/del",
    method: "POST",
    data,
  });
};
// 删除
export const apiDelMember = (params) => {
  return request({
    url: "/monitor/device/member/del",
    method: "POST",
    params,
  });
};
// 详情
export const apiMemberDetail = (data) => {
  return request({
    url: "/monitor/device/member/detail",
    method: "GET",
    data,
  });
};
// 列表
export const apiMemberPage = (data) => {
  return request({
    url: "/monitor/device/member/page",
    method: "POST",
    data,
  });
};
// 新增
export const apiAddMember = (data) => {
  return request({
    url: "/monitor/device/member/save",
    method: "POST",
    data,
  });
};
// 更新
export const apiUpdateMember = (data) => {
  return request({
    url: "/monitor/device/member/update",
    method: "POST",
    data,
  });
};
