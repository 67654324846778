<template>
  <gc-dialog
    :show.sync="visible"
    :title="title"
    @ok="handleOk"
    @cancel="visible = false"
    @close="visible = false"
  >
    <!-- 下载提示 -->
    <div class="box-tip">
      <i class="iconfont icon-tip"></i>
      <span class="text">
        上传前请先
        <a class="download" :href="downloadPath"> 点击下载模板 </a>
        ，模板编辑完成后再执行上传功能，若已完成上述步骤，请忽略本条提醒
      </span>
    </div>
    <el-form ref="form" :model="form" label-position="top">
      <el-form-item label="上传文件：" required>
        <div v-if="fileName" class="box-file selected">
          <img class="icon-xls" src="@/assets/images/pic/xls.svg" />
          {{ fileName }}
          <i class="iconfont el-icon-close" @click="handleClearFile"></i>
        </div>
        <div v-else class="box-file empty">
          <el-upload
            ref="upload"
            class="box-upload"
            action
            :auto-upload="false"
            :show-file-list="false"
            :on-change="handleFileChange"
          >
            + 选取文件
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item label="过期时间" prop="expirationTime" v-if="isAdd">
        <el-date-picker
          v-model="form.expirationTime"
          type="datetime"
          placeholder="请选择过期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
  </gc-dialog>
</template>

<script>
import { apiBatchAddMember, apiBatchDelMember } from "@/api/member";
import { isArray } from "@/utils/validate";
import { formatTime } from "@/utils";
import XLSX from "xlsx";
import { export_json_to_excel as Export2Excel } from "@/utils/excel/Export2Excel";

// 批量新增对应版本号和模板类型名称
const AddConfig = {
  version: "20230117",
  template: "DEVICE_MEMBER_BATCH_REGISTER",
};
const DeleteConfig = {
  version: "20230117",
  template: "DEVICE_MEMBER_BATCH_DELETE",
};

export default {
  name: "BatchDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      form: {
        expirationTime: "",
      },
      excelVersion: null, // 版本号
      templateType: null, // 模板类型
      fileName: "", // 文件名
      excelList: [], // 解析后的数组
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
    isAdd() {
      return this.type === "add";
    },
    title() {
      return this.isAdd ? "批量新增" : "批量删除";
    },
    downloadPath() {
      const templateName = this.isAdd
        ? "设备会员批量新增模板.xls"
        : "设备会员批量删除模板.xls";
      return `${this.publicPath}excel/${templateName}`;
    },
  },
  watch: {
    show() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  methods: {
    // 上传文件
    handleFileChange(file) {
      if (!/\.(xlsm|xls)$/.test(file.name.toLowerCase())) {
        this.$message.error("上传文件格式不正确，请上传xlsm格式文档");
        return false;
      }
      const loading = this.$loading({ text: "正在读取，请等待…" });
      this.fileName = file.name;
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const sheetNames = workbook.SheetNames;
          const wsname = sheetNames[0]; //取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格
          this.excelList = ws;
          const v = XLSX.utils.sheet_to_json(workbook.Sheets["版本号"]);
          if (v && v instanceof Array && v.length) {
            this.excelVersion = v[0]["版本号"];
          } else {
            this.excelVersion = null;
          }
          const type = XLSX.utils.sheet_to_json(workbook.Sheets["模板类型"]);
          if (type && type instanceof Array && type.length) {
            this.templateType = type[0]["模板类型"];
          } else {
            this.templateType = null;
          }
          console.info("当前的版本号为--" + this.excelVersion);
          console.info("当前模板的类型为--" + this.templateType);
          setTimeout(() => {
            loading.close();
          }, 2000);
        } catch {
          loading.close();
          this.$message.error("excel读取发生错误，请重新读取");
          return false;
        }
      };
      fileReader.readAsBinaryString(file.raw);
    },
    // 清除已上传文件
    handleClearFile() {
      this.fileName = null;
      this.excelList = [];
    },
    // 点击提交
    handleOk() {
      let flag = false;
      this.$refs.form.validate((valid) => {
        flag = valid;
      });
      if (!flag) return;
      if (!this.fileName) return this.$message.error("请选取文件");
      const { template, version } = this.isAdd ? AddConfig : DeleteConfig;
      console.log(template, version);
      if (this.templateType !== template) {
        this.$message.error("模板错误，请重新下载上传");
        return;
      }
      if (this.excelVersion != version) {
        this.$message.error("文件校验未通过，请从页面上下载最新的模板！");
        return;
      }
      if (!isArray(this.excelList)) {
        this.$message.error("Excel内容解析错误");
        return;
      }
      if (!this.excelList.length) {
        this.$message.error("Excel内容为空");
        return;
      }
      if (this.excelList.length > 1500) {
        this.$message.error("Excel最多只能上传1500条");
        return;
      }
      this.handleUploadRequest();
    },
    handleUploadRequest() {
      const api = this.isAdd ? apiBatchAddMember : apiBatchDelMember;
      const dataList = this.excelList.map((item) => {
        const data = {
          deviceNo: item["设备编号"],
        };
        if (this.isAdd) {
          data.expirationTime = item["过期时间"] || "";
        }
        return data;
      });
      const params = {
        dataList,
      };
      if (this.isAdd) {
        params.expirationTime = this.form.expirationTime;
      }
      api(params)
        .then((res) => {
          if (res?.length) {
            this.$confirm("本次操作含有错误日志，是否下载？")
              .then(() => {
                this.handleDownloadError(res);
              })
              .catch(() => {
                console.log("取消");
              });
          }
          this.$message.success(
            this.isAdd ? "批量新增会员成功！" : "批量删除会员成功！"
          );
          this.visible = false;
          this.$emit("update");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 下载错误日志
    handleDownloadError(list) {
      const excelTHead = ["错误日志"];
      const excelData = [];
      list.map((item) => excelData.push([item]));
      const excelName = `${this.title}错误日志${formatTime("y-m-d h:m:s")}`;
      Export2Excel(excelTHead, excelData, excelName);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-textarea__inner {
  height: 64px;
}
.box-tip {
  padding: 8px;
  background: #e9f1ff;
  border-radius: 3px;
  font-size: 12px;
  color: #2f87fe;
  line-height: 20px;
  display: flex;
  .iconfont {
    font-size: 12px;
    margin-right: 8px;
  }
  .download {
    color: #ff6a4d;
    text-decoration: underline;
  }
}
.el-form {
  padding-top: 20px;
}
.box-file {
  width: 100%;
  min-height: 88px;
  border-radius: 4px;
  @include flex-center;
  position: relative;
  .box-upload {
    position: absolute;
    width: 100%;
    height: 100%;
    ::v-deep .el-upload {
      width: 100%;
      height: 100%;
      @include flex-center;
    }
  }
  &.empty {
    border: 1px dashed #d5d5d5;
    color: #909090;
  }
  &.selected {
    border: 1px solid #d9d9d9;
    color: #303030;
    justify-content: flex-start;
    padding-right: 30px;
    line-height: 20px;
    .icon-xls {
      margin-left: 16px;
      margin-right: 18px;
    }
    .iconfont {
      font-size: 12px;
      color: #c0c4cc;
      padding: 1px;
      cursor: pointer;
      position: absolute;
      right: 10px;
      border: 1px solid #c0c4cc;
      border-radius: 50%;
      transform: scale(0.8);
    }
  }
}
</style>
