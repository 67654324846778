<template>
  <div class="page">
    <gc-header header-data="memberManage">
      <template #ops>
        <el-button
          v-has="'monitor:device:member:maintain'"
          type="primary"
          plain
          @click="handleBatchAdd()"
        >
          批量新增
        </el-button>
        <el-button
          v-has="'monitor:device:member:maintain'"
          type="warning"
          plain
          @click="handleBatchDelete()"
        >
          批量删除
        </el-button>
        <el-button
          v-has="'monitor:device:member:maintain'"
          type="primary"
          @click="handleMember()"
          >新增会员</el-button
        >
      </template>
    </gc-header>
    <div class="page-main">
      <gc-custom-search
        key-word="设备编号"
        :search.sync="searchKey"
        @keyup.enter.native="currentPageChange(1)"
      >
        <template #icon>
          <i class="el-icon-search pointer" @click="currentPageChange(1)"></i>
        </template>
      </gc-custom-search>
      <gc-table
        class="table"
        :columns="columns"
        :table-data="tableData"
        :border="true"
        :show-page="true"
        :current-page="pageInfo.current"
        :total="pageInfo.total"
        @current-page-change="currentPageChange"
      ></gc-table>
    </div>
    <!-- 新增修改会员弹框 -->
    <edit-dialog :show.sync="visible" :detail="detail" @update="updateList">
    </edit-dialog>
    <!-- 会员批量操作弹框 -->
    <batch-dialog
      :show.sync="showBatchDialog"
      :type="batchType"
      @update="updateList"
    >
    </batch-dialog>
  </div>
</template>

<script>
import EditDialog from "./EditDialog.vue";
import BatchDialog from "./BatchDialog.vue";
import { apiMemberPage, apiDelMember } from "@/api/member";

export default {
  name: "memberManage",
  components: { EditDialog, BatchDialog },
  data() {
    return {
      columns: [
        { key: "deviceNo", name: "设备编号" },
        { key: "expirationTime", name: "到期时间" },
        { key: "createTime", name: "创建时间" },
        { key: "modifyTime", name: "修改时间" },
        this.$has(["monitor:device:member:maintain"])
          ? {
              key: "ops",
              name: "操作",
              render: (h, row) => {
                return (
                  <div>
                    <span
                      class="ops-btn"
                      onClick={() => this.handleMember(row)}
                    >
                      修改
                    </span>
                    <span
                      class="ops-btn delete"
                      onClick={() => this.handleDelete(row)}
                    >
                      删除
                    </span>
                  </div>
                );
              },
            }
          : null,
      ].filter(Boolean),
      tableData: [],
      pageInfo: {
        current: 1,
        size: 10,
        total: 0,
      },
      searchKey: "",
      visible: false,
      detail: null,
      showBatchDialog: false,
      batchType: "add", // 新增：add，删除：delete
    };
  },
  mounted() {
    this.getMemberList();
  },
  methods: {
    // 批量新增
    handleBatchAdd() {
      this.batchType = "add";
      this.showBatchDialog = true;
    },
    // 批量删除
    handleBatchDelete() {
      this.batchType = "delete";
      this.showBatchDialog = true;
    },
    getMemberList() {
      const { current = 1, size = 10 } = this.pageInfo;
      const params = {
        current,
        size,
        deviceNo: this.searchKey.trim(),
      };
      apiMemberPage(params)
        .then(({ records = [], ...pageInfo }) => {
          this.tableData = records;
          this.pageInfo = {
            ...this.pageInfo,
            ...pageInfo,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    currentPageChange(current) {
      this.pageInfo.current = current;
      this.getMemberList();
    },
    // 新增、修改
    handleMember(row) {
      this.detail = row || null;
      this.visible = true;
    },
    handleDelete({ deviceNo }) {
      this.$confirm("你确定要删除该会员吗？删除后将不可撤销。").then(() => {
        apiDelMember({ deviceNo }).then(() => {
          this.$message.success("删除成功");
          this.updateList();
        });
      });
    },
    // 新增、修改后更新列表
    updateList(value) {
      // 新增时重置搜索条件
      if (!value) {
        this.pageInfo.current = 1;
        this.searchKey = "";
      }
      this.visible = false;
      this.getMemberList();
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  @include base-button($width: 120px);
}
.page-main {
  flex-direction: column;
  padding: 20px 24px;
}
.table {
  margin-top: 22px;
}
::v-deep .ops-btn {
  cursor: pointer;
  color: $base-color-theme;
  + .ops-btn {
    margin-left: 22px;
    color: #ff6a4d;
  }
}
</style>
